import React from "react"
import { createRoot } from "react-dom/client";
import BusinessCard from "./components/BusinessCard.js"
import "./styles/index.css"

export default function App () {
    return (
        <BusinessCard />
    );
}
const container = createRoot(document.getElementById("root"));
container.render(<App />);