import React from "react"
import "../styles/BusinessCard.css"
import "../styles/icons.css"
import cardPicture from "../images/cardpicture-small.jpg"
import mycv from '../files/papadopoulos-ilias-cv.pdf'

export default function BusinessCard () {
    const workListItems = getWorkListItems();
    // return workListItems;
    return (
        <div className="businessCard">
            <img className="businessCard__picture" width="380" src={cardPicture} />
            <div className="businessCard__details">
                <h1 className="businessCard__detailsName">Papadopoulos Ilias</h1>
                <h2 className="businessCard__detailsPosition">Full-Stack Developer</h2>
                <a className="businessCard__detailsWebsite" href="https://chinchillabrains.com">chinchillabrains.com</a>
                <div className="businessCard__detailsButtons">
                    <a href="mailto:chinchillabrains@gmail.com" className="businessCard__detailsButton businessCard__detailsButton--white">
                        <i className="icon-envelope"></i>Email
                    </a>
                    <a href="https://www.linkedin.com/in/ipap/" target="_blank" className="businessCard__detailsButton businessCard__detailsButton--lightblue">
                        <i className="icon-linkedin"></i>Linkedin
                    </a>
                </div>
                <div className="businessCard__detailsMain businessCard__detailsMain--margin_sm">
                    <h3 className="businessCard__detailsMainTitle">About me</h3>
                    <p className="businessCard__detailsMainContent">Full-Stack Developer, passionate about performance tuning. Always striving to learn something new.</p>
                </div>
                <div className="businessCard__detailsMain businessCard__detailsMain--margin_sm">
                    {/* <h3 className="businessCard__detailsMainTitle">Tech Experience</h3> */}
                    <ul className="businessCard__detailsMainList businessCard__detailsMainList--icons">
                        <li className="businessCard__detailsMainListItem businessCard__detailsMainListItem--icon"><i className="businessCard__detailsIcon icon-php" title="PHP"></i><span className="businessCard__detailsMainListItemLabel">PHP</span></li>
                        <li className="businessCard__detailsMainListItem businessCard__detailsMainListItem--icon"><i className="businessCard__detailsIcon icon-python" title="Python"></i><span className="businessCard__detailsMainListItemLabel">Python</span></li>
                        <li className="businessCard__detailsMainListItem businessCard__detailsMainListItem--icon"><i className="businessCard__detailsIcon icon-javascript" title="Javascript"></i><span className="businessCard__detailsMainListItemLabel">Javascript</span></li>
                        <li className="businessCard__detailsMainListItem businessCard__detailsMainListItem--icon"><i className="businessCard__detailsIcon icon-html5" title="HTML"></i><span className="businessCard__detailsMainListItemLabel">HTML</span></li>
                        <li className="businessCard__detailsMainListItem businessCard__detailsMainListItem--icon"><i className="businessCard__detailsIcon icon-css3" title="CSS"></i><span className="businessCard__detailsMainListItemLabel">CSS</span></li>
                        <li className="businessCard__detailsMainListItem businessCard__detailsMainListItem--icon"><i className="businessCard__detailsIcon icon-react" title="React"></i><span className="businessCard__detailsMainListItemLabel">React</span></li>
                        <li className="businessCard__detailsMainListItem businessCard__detailsMainListItem--icon"><i className="businessCard__detailsIcon icon-laravel" title="Laravel"></i><span className="businessCard__detailsMainListItemLabel">Laravel</span></li>
                        <li className="businessCard__detailsMainListItem businessCard__detailsMainListItem--icon"><i className="businessCard__detailsIcon icon-django" title="Django"></i><span className="businessCard__detailsMainListItemLabel">Django</span></li>
                        <li className="businessCard__detailsMainListItem businessCard__detailsMainListItem--icon"><i className="businessCard__detailsIcon icon-node-dot-js" title="Node.js"></i><span className="businessCard__detailsMainListItemLabel">Node.js</span></li>
                        <li className="businessCard__detailsMainListItem businessCard__detailsMainListItem--icon"><i className="businessCard__detailsIcon icon-docker" title="Docker"></i><span className="businessCard__detailsMainListItemLabel">Docker</span></li>
                    </ul>
                </div>
                <div className="businessCard__detailsMain">
                    <h3 className="businessCard__detailsMainTitle">What I do</h3>
                    <ul className="businessCard__detailsMainList">
                        <li className="businessCard__detailsMainListItem">Django apps</li>
                        <li className="businessCard__detailsMainListItem">Laravel apps</li>
                        <li className="businessCard__detailsMainListItem">React apps</li>
                        <li className="businessCard__detailsMainListItem">WordPress websites &amp; plugins</li>
                        <li className="businessCard__detailsMainListItem">E-shops</li>
                        <li className="businessCard__detailsMainListItem">APIs</li>
                        <li className="businessCard__detailsMainListItem">Bridges with ERP</li>
                        <li className="businessCard__detailsMainListItem">Machine learning systems</li>
                        <li className="businessCard__detailsMainListItem">Web scraping</li>
                    </ul>
                </div>
                <div className="businessCard__detailsMain">
                    <h3 className="businessCard__detailsMainTitle">Professional Experience</h3>
                    <ul className="businessCard__detailsMainList">
                        {workListItems.map((item, index) => {
                            return <li className="businessCard__detailsMainListItem" key={index}>{item}</li>;
                        })}
                    </ul>
                </div>
                <div className="businessCard__detailsMain">
                    <h3 className="businessCard__detailsMainTitle">How I like spending my spare time</h3>
                    <ul className="businessCard__detailsMainList">
                        <li className="businessCard__detailsMainListItem">Playing music (Guitar, Ukulele, Singing)</li>
                        <li className="businessCard__detailsMainListItem">Making silly video game prototypes (Unity)</li>
                        <li className="businessCard__detailsMainListItem">Reading non-fiction books</li>
                    </ul>
                </div>
            </div>
            <div className="businessCard__foot">
                <ul className="businessCard__footList">
                    <li className="businessCard__footListItem"><a className="businessCard__footLink" target="_blank" href="https://github.com/chinchillabrains"><i className="icon-github"></i></a></li>
                    <li className="businessCard__footListItem"><a className="businessCard__footLink" href="https://chinchillabrains.com/"><i className="icon-earth"></i></a></li>
                    <li className="businessCard__footListItem"><a className="businessCard__footLink" target="_blank" href="https://profiles.wordpress.org/elpap/"><i className="icon-wordpress"></i></a></li>
                    <li className="businessCard__footListItem"><a className="businessCard__footLink" target="_blank" download="papadopoulos-ilias-cv.pdf" href={mycv}><i className="icon-profile"></i></a></li>
                </ul>
            </div>
        </div>
    );
}

function getWorkExp () {
    return [
        {
            role: 'Full-Stack Developer',
            from: new Date('2020-01-01'),
            to:   new Date()
        },
        {
            role: 'Front-End Developer',
            from: new Date('2019-06-01'),
            to:   new Date('2020-01-01')
        },
        {
            role: 'Full-Stack Developer',
            from: new Date('2017-03-01'),
            to:   new Date('2018-11-01')
        },
    ];
}

function getWorkListItems () {
    const workExperience = getWorkExp();
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return workExperience.map((job, index) => {
        const datesDiffMonths = parseInt((job.to - job.from)/(1000*60*60*24*30));
        const datesDiffYears = parseInt(datesDiffMonths/12);
        const yearDiffStr = (datesDiffYears + ' yrs ' + datesDiffMonths%12 + ' mos').replace(/^0 yrs /, '');
        return job.role + ' | ' + months[job.from.getMonth()] + ' ' + job.from.getFullYear() + ' - ' + months[job.to.getMonth()] + ' ' + job.to.getFullYear() + ' (' + yearDiffStr + ')';
    });
}